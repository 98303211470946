import {ActionContext} from 'vuex/types'
import {EagleFormConfigInterface} from 'components/form/types/form'
declare interface AnyObject {[key: string]: any}

declare interface FomInstanceStateInterface {
  bindRoute: boolean,
  hasWritePermission: boolean,
  config: AnyObject,
  mode: string|null,
  api: AnyObject | null,
  originalData: AnyObject|null,
  data: AnyObject|null,
  actions: AnyObject|null,
  meta: null,
  blocks: [],
  changed: AnyObject,
  finished: AnyObject,
  error: AnyObject,
  loadFailure: boolean,
}

declare interface FormInstanceInterface {
  namespaced: boolean
  state: () => FomInstanceStateInterface
  mutations: AnyObject
  actions: AnyObject
  getters: AnyObject
}

declare interface FormInstanceOptionInterface {
  config: EagleFormConfigInterface
  bindRoute: boolean
  hasWritePermission: boolean
}

export default (options: FormInstanceOptionInterface) : FormInstanceInterface => {
  const {
    config,
    bindRoute,
    hasWritePermission
  } = options

  return {
    namespaced: true,
    state: () => ({
      bindRoute,
      hasWritePermission,
      mode: null,
      meta: null,
      api: null,
      config,
      originalData: null,
      data: null,
      actions: {},
      blocks: [],
      finished: {},
      changed: {},
      error: {},
      loadFailure: false,
    }),
    mutations: {
      reset(state: FomInstanceStateInterface) {
        state.finished = {}
        state.changed = {}
        state.error = {}
      },
      set: (state: FomInstanceStateInterface, data: {[key:string]: any}) => {
        // @ts-ignore
        state[data.key] = data.value
        window.eagleLodash.set(state, data.key, data.value)
      },
      setOriginalData: (state: FomInstanceStateInterface, data: any) => {
        state.originalData = data
      },
      setData: (state: FomInstanceStateInterface, data: any) => {
        state.data = data
      },
      setDataColumn: (state: FomInstanceStateInterface, config: { key: string, value: any }) => {
        const formData = window.eagleLodash.cloneDeep(state.data)
        const properties = config.key.split('.')
        window.eagleLodash.set(formData, properties, window.eagleLodash.cloneDeep(config.value))
        state.data = formData
      },
      setActions: (state: FomInstanceStateInterface, actions: any) => {
        state.actions = actions
      },
      setApi: (state: FomInstanceStateInterface, api: { [key:string]: any }) => {
        state.api = api
      },
      setMeta(state: FomInstanceStateInterface, meta: { [key:string]: any }|null) {
        state.meta = window.eagleLodash.cloneDeep(meta)
      },
      setMetaProperty(state: FomInstanceStateInterface, config: { key: string, value: any }) {
        const meta = window.eagleLodash.cloneDeep(state.meta)
        window.eagleLodash.set(meta, config.key, config.value)
        state.meta = meta
      },
      setBlocks(state: FomInstanceStateInterface, blocks: []) {
        state.blocks = blocks
      },
      setDataChangedStatus(state: FomInstanceStateInterface, config: { key: string, changed: boolean }) {
        const changed = window.eagleLodash.cloneDeep(state.changed)
        changed[config.key] = config.changed
        state.changed = changed
      },
      setDataFinishedStatus(state: FomInstanceStateInterface, config: { key: string, finished: boolean }) {
        const finished = window.eagleLodash.cloneDeep(state.finished)
        finished[config.key] = config.finished
        state.finished = finished
      },
      setError(state: FomInstanceStateInterface, error: AnyObject) {
        state.error = typeof error != 'object' ? {} : error
      },
    },
    actions: {
      reset: (context: ActionContext<any, any>) => {
        context.commit('reset')
      },
      set: (context: ActionContext<any, any>, data: {[key:string]: any}) => {
        context.commit('set', data)
      },
      setOriginalData: (context: ActionContext<any, any>, data: any) => {
        context.commit('setOriginalData', data)
      },
      setData: (context: ActionContext<any, any>, data: any) => {
        context.commit('setData', data)
      },
      setDataColumn: (context: ActionContext<any, any>, config: { key: string, value: any }) => {
        context.commit('setDataColumn', config)
      },
      setActions: (context: ActionContext<any, any>, actions: any) => {
        context.commit('setActions', actions)
      },
      setApi: (context: ActionContext<any, any>, api: { [key:string]: any }) => {
        context.commit('setApi', api)
      },
      setMeta: (context: ActionContext<any, any>, meta: { [key:string]: any }|null) => {
        context.commit('setMeta', meta)
      },
      setBlocks: (context: ActionContext<any, any>, blocks: []) => {
        context.commit('setBlocks', blocks)
      },
      setDataChangedStatus: (context: ActionContext<any, any>, config: { key: string, changed: boolean }) => {
        context.commit('setDataChangedStatus', config)
      },
      setDataFinishedStatus: (context: ActionContext<any, any>, config: { key: string, finished: boolean }) => {
        context.commit('setDataFinishedStatus', config)
      },
      setError: (context: ActionContext<any, any>, error: AnyObject) => {
        context.commit('setError', error)
      },
    },
    getters: {
      loadFailure: (state: FomInstanceStateInterface) => state.loadFailure,
      config: (state: FomInstanceStateInterface) => state.config,
      bindRoute: (state: FomInstanceStateInterface, getters: AnyObject) => state.bindRoute,
      hasWritePermission: (state: FomInstanceStateInterface, getters: AnyObject) => state.hasWritePermission,
      blocks: (state: FomInstanceStateInterface, getters: AnyObject) => state.blocks,
      mode: (state: FomInstanceStateInterface) => state.mode,
      meta: (state: FomInstanceStateInterface) => state.meta,
      data: (state: FomInstanceStateInterface) => state.data,
      actions: (state: FomInstanceStateInterface) => state.actions,
      api: (state: FomInstanceStateInterface) => state.api,
      originalData: (state: FomInstanceStateInterface) => state.originalData,
      dataAction: (state: FomInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['dataAction']) || {}
      },
      defaultData: (state: FomInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['defaultData'])
      },
      info: (state: FomInstanceStateInterface, getters: AnyObject) => {
        return {
          formConfig: window.eagleLodash.cloneDeep(getters.config),
          formData: typeof getters.data != 'object' ? {} : window.eagleLodash.cloneDeep(getters.data),
          formMeta: window.eagleLodash.cloneDeep(getters.meta),
          formMode: window.eagleLodash.cloneDeep(getters.mode),
          hasWritePermission: getters.hasWritePermission,
        }
      },
      hasChanged: (state: FomInstanceStateInterface) => {
        for(const property in state.changed) {
          const changed = state.changed[property]
          if(changed === true) return true
        }
        return false
      },
      finished: (state: FomInstanceStateInterface) => {
        for(const property in state.finished) {
          const finished = state.finished[property]
          if(finished === false) return false
        }
        return true
      },
      error: (state: FomInstanceStateInterface) => state.error,
      targetKey: (state: FomInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.config, ['targetKey']) || 'id'
      },
      target: (state: FomInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(getters.data, getters.targetKey) || null
      },
      metaAction: (state: FomInstanceStateInterface, getters: AnyObject) => {
        return window.eagleLodash.get(state.config, ['metaAction']) || null
      },
    },
  }
}
