<template>
  <v-form
    eagle-form="root"
    @submit.prevent
    lazy-validation
    v-if="formConfig"
    :ref="ref"
    :bind-route="bindRoute"
    :style="formStyle"
  >
    <formMetaAction
      v-if="hasWritePermission"
      :formKey="formKey"
    ></formMetaAction>

    <v-row v-if="formData">
      <formBlock
        v-for="(block, index) in formBlocks"
        :eagle-form-block="index"
        :block="block"
        :key="index"
        :blockIndex="index"
        :formKey="formKey"
      ></formBlock>
    </v-row>

    <formAction
      :form-key="formKey"
    ></formAction>

    <!--找不到資料-->
    <data-not-found
      v-if="loadFailure"
    ></data-not-found>
  </v-form>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  created() {
    this.$root.$on(`form/${this.formKey}/submit`, this.onSubmit)
  },
  methods: {
    onFormChangedStatusUpdate() {
      if(!this.bindRoute) return // 沒綁路由就不須管關閉或離開頁面
      if(this.hasChanged) {
        window.onbeforeunload = (event) => {
          const message = this.$t('exit.confirm')
          event.returnValue = message
          return message
        }
      }

      else {
        window.onbeforeunload = null
      }

      window.vueRouteChangePrevent.setup(this.formKey, this.hasChanged)
    },
    onSubmit() {
      const $form = this.$refs[this.ref]
      if(!$form) return
      $form.validate()
    },
    initBlocks() {
      let blocks = this.configBlocks.filter(block => {
        if(typeof block.create != 'function') return true
        return block.create(this.formInfo, this.tokenStore) === true
      })

      if(window.eagleLodash.isEqual(blocks, this.formBlocks)) return
      this.$store.dispatch(`form/${this.formKey}/setBlocks`, blocks)
    },
  },
  computed: {
    hasWritePermission() {
      return this.$store.getters[`form/${this.formKey}/hasWritePermission`]
    },
    loadFailure() {
      return this.$store.getters[`form/${this.formKey}/loadFailure`]
    },
    bindRoute() {
      return this.$store.getters[`form/${this.formKey}/bindRoute`]
    },
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    hasChanged() {
      return this.$store.getters[`form/${this.formKey}/hasChanged`]
    },
    formBlocks() {
      return this.$store.getters[`form/${this.formKey}/blocks`]
    },
    tokenStore() {
      return this.$store.getters[`token/tokenStore`]
    },
    configBlocks() {
      if(!this.formConfig) return []
      return this.formConfig.blocks
    },
    ref() {
      return `eagle-form-${this.formKey}`
    },
    formStyle() {
      return {
        minHeight: this.minHeight
      }
    },
    minHeight() {
      return this.formConfig.minHeight || '80vh'
    },
  },
  watch: {
    configBlocks: {
      deep: true,
      handler() {
        this.initBlocks()
      },
    },
    formData: {
      deep: true,
      async handler() {
        this.initBlocks()

        await this.$nextTick()
        this.formActions.setupPageTitle()
      },
    },
    hasChanged: {
      immediate: true,
      handler() {
        this.onFormChangedStatusUpdate()
      },
    },
  },
  components: {
    formMetaAction: () => import('@/components/form/formMetaAction.vue'),
    formBlock: () => import('@/components/form/formBlock.vue'),
    formAction: () => import('@/components/form/formAction.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
